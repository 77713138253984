import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import {
  Container,
  MainText,
  PrimaryTitle,
  SecondaryTitle,
} from 'styles/Global'
import {
  LegalsContent,
  LegalsImage,
  LegalsParagraph,
  LegalsSection,
} from 'styles/pages/legals.style'
import { fetchLegalsImg } from 'hooks/fetch-legals-img'
import Image from 'components/Image'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Header from 'components/organisms/Header/Header'
import Footer from 'components/molecules/Footer/Footer'
import { I18n } from '@lingui/react'
import sanitize from "sanitize-html"

interface Props {}

const CGUPage: FunctionComponent<Props> = () => {
  const file = fetchLegalsImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO title={i18n._("privacy.metaTitle")} />
        )}
      </I18n>
      <Header />
      <LegalsSection>
        <LegalsImage>
          <Image fluid={file.fluid} alt={file.alt} />
        </LegalsImage>
        <I18n>
          {({ i18n }) => (
            <Container>
              <LegalsContent>
                <PrimaryTitle>
                  <Trans id="privacy.title">CGU</Trans>
                </PrimaryTitle>
                <LegalsParagraph>
                  <SecondaryTitle>
                    <Trans id="privacy.paragraph1.title">
                      Charte de confidentialité
                    </Trans>
                  </SecondaryTitle>
                  <MainText>
                    <Trans id="privacy.paragraph1.text.1">
                      Nous prenons votre vie privée très au sérieux !
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="privacy.paragraph1.text.2">
                      Merci d’utiliser nos sites Web et nos services ! Comme
                      vous, nous croyons que l’intégrité personnelle est
                      importante et nous prenons votre vie privée au sérieux.
                      Cette charte de confidentialité est pertinente pour toute
                      personne physique partageant ses données personnelles
                      directement ou indirectement avec Redfabriq en visitant
                      nos sites Web ou en utilisant nos services.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="privacy.paragraph1.text.3">
                      Redfabriq est une société établie en France et, en tant
                      que telle, nous adhérons à la législation française sur la
                      protection des informations personnelles (1998: 204) qui
                      applique la Directive sur la protection des données
                      européennes 95/46/EC.
                    </Trans>
                  </MainText>
                  <MainText
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i18n._('privacy.paragraph1.text.4')),
                    }}
                  />
                  <MainText>
                    <Trans id="privacy.paragraph1.text.5">
                      Dans cette charte de confidentialité, nous expliquons
                      quels types de données personnelles sont susceptibles
                      d’être traitées et dans quelle finalité. Nous expliquons
                      également les choix que vous avez par rapport à notre
                      traitement et la façon dont vous pouvez en apprendre
                      davantage sur notre traitement et sur vos droits.
                    </Trans>
                  </MainText>
                </LegalsParagraph>
                <LegalsParagraph>
                  <SecondaryTitle>
                    <Trans id="privacy.paragraph2.title">
                      Portée de la charte de confidentialité
                    </Trans>
                  </SecondaryTitle>
                  <MainText>
                    <Trans id="privacy.paragraph2.text.1">
                      Veuillez noter que cette charte de confidentialité concerne
                      le traitement des données personnelles dont Redfabriq est le
                      Responsable de Traitement, c’est-à-dire que Redfabriq a
                      décidé des finalités et des moyens de traitement.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="privacy.paragraph2.text.2">
                      Pour éviter toute ambiguïté, cette Charte de confidentialité
                      ne concerne aucun traitement de données personnelles que
                      Redfabriq peut effectuer en tant que Sous-Traitant découlant
                      de votre utilisation de nos services.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="privacy.paragraph2.text.3">
                      Pour obtenir plus d’informations sur la façon dont nos
                      services, peuvent être utilisés pour faciliter vos
                      traitements de données personnelles, et sur les
                      responsabilités et les engagements de Redfabriq en relation
                      avec ceux-ci, veuillez-vous référer aux conditions
                      applicables.
                    </Trans>
                  </MainText>
                </LegalsParagraph>
                <LegalsParagraph>
                  <SecondaryTitle>
                    <Trans id="privacy.paragraph3.title">
                      Finalités de traitement
                    </Trans>
                  </SecondaryTitle>
                  <MainText>
                    <Trans id="privacy.paragraph3.text.1">
                      Nous traitons vos données personnelles pour les finalités
                      suivantes : Fournir nos services conformément aux conditions
                      applicables. Administrer la relation d’affaires avec vous et
                      le client de Redfabriq que vous représentez. Développer et
                      améliorer nos services.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="privacy.paragraph3.text.2">
                      Afin de fournir des informations et des offres aux clients
                      de Redfabriq actuels ou potentiels sur nos services par des
                      parties tiers sélectionnés. Pour éviter toute ambiguïté,
                      tout matériel de marketing distribué à l’aide de vos données
                      personnelles vous est envoyé en tant que représentant de
                      client actuel ou potentiel de Redfabriq et non à vous en
                      tant que personne privée.
                    </Trans>
                  </MainText>
                </LegalsParagraph>
                <LegalsParagraph>
                  <SecondaryTitle>
                    <Trans id="privacy.paragraph4.title">
                      Vos droits
                    </Trans>
                  </SecondaryTitle>
                  <MainText>
                    <Trans id="privacy.paragraph4.text.1">
                      Il est de notre obligation de traiter des éléments
                      personnels précis, pertinents et nécessaires tout en tenant
                      compte de nos finalités légitimes. Il est de votre droit de
                      nous contrôler.
                    </Trans>
                  </MainText>
                  <MainText
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i18n._('privacy.paragraph4.text.2')),
                    }}
                  />
                </LegalsParagraph>
              </LegalsContent>
            </Container>
          )}
        </I18n>
      </LegalsSection>
      <Footer />
    </Layout>
  )
}

export default CGUPage
