import styled from 'styled-components'
import { MainText, PrimaryTitle, SecondaryTitle } from 'styles/Global'

export const LegalsSection = styled.section`
  margin-top: 95px;
  position: relative;
  padding-top: 240px;
  margin-bottom: 150px;
  overflow-x: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 180px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 28px;
    padding-top: 150px;
  }
`

export const LegalsImage = styled.div`
  width: 100%;
  max-width: 400px;
  transform: rotate(15deg);
  position: absolute;
  z-index: -1;
  top: 0;
  right: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 300px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 250px;
    right: 10px;
  }
`

export const LegalsContent = styled.div`
  background: ${({ theme }) => theme.Colors.mainBlack};
  color: ${({ theme }) => theme.Colors.white};
  padding: 7.5rem 6.5rem 5rem;

  ${PrimaryTitle} {
    margin-bottom: 1rem;
  }

  ${SecondaryTitle}, ${MainText} {
    margin-bottom: 1.5rem;
    line-height: 35px;
  }

  a {
    color: ${({ theme }) => theme.Colors.silver};
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 5rem 4rem 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 3rem 2.75rem 5rem;

    ${PrimaryTitle} {
      margin-bottom: 2rem;
    }

    ${SecondaryTitle}, ${MainText} {
      margin-bottom: 1rem;
    }

    ${MainText} {
      font-size: 12px;
      line-height: 21px;
    }
  }
`

export const LegalsParagraph = styled.div`
  margin-bottom: 3rem;
`
